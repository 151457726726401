import type { PriceType } from '@eventbrite/discover-utils';
import type { DestinationEvent } from '@eventbrite/event-renderer';
import { sdkRequest } from '@eventbrite/http';
import type { AggType } from '../constants/agg';
import type { DateAggResponse, DateRange, DateType } from '../constants/dates';
import type { SortType } from '../constants/sort';

const DESTINATION_SEARCH_ENDPOINT = '/api/v3/destination/search/';
const SEARCH_BFF_ENDPOINT = '/d/api/search/';

export function searchOrganicEvents(
    search: OrganicEventSearchApiPayload,
    { useBff = false } = {},
): Promise<OrganicEventSearchApiResponse> {
    if (useBff) {
        return fetchSearchOrganicEventsBff(search);
    } else {
        return sdkRequest(DESTINATION_SEARCH_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify(search),
        });
    }
}

function fetchSearchOrganicEventsBff(
    search: OrganicEventSearchApiPayload,
): Promise<OrganicEventSearchApiResponse> {
    return fetch(SEARCH_BFF_ENDPOINT, {
        method: 'POST',
        headers: {
            contentType: 'application/json',
        },
        body: JSON.stringify(search),
    }).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<OrganicEventSearchApiResponse>;
    });
}

export interface OrganicEventSearchApiPayload {
    browse_surface:
        | 'category_browse'
        | 'homepage'
        | 'search'
        | 'browse'
        | 'listings';
    event_search: OrganicEventSearchFiltersApiPayload;
    'expand.destination_event'?: string[];
    'expand.destination_profile'?: string[];
}

export interface OrganicEventSearchFiltersApiPayload {
    dates?: DateType | DateType[];
    tags?: string[];
    price?: PriceType;
    price_range?: {
        minimum_price: number;
        maximum_price: number;
        currency: string;
    };
    q?: string;
    places?: string[];
    bbox?:
        | string
        | {
              sw: { lng: number; lat: number };
              ne: { lng: number; lat: number };
          };
    currencies?: any;
    dedup?: boolean;
    date_range?: DateRange;
    hash?: string;
    languages?: any;
    online_events_only?: boolean;
    online_events_excluded?: boolean;
    sold_out_status?: boolean;
    available?: boolean;
    organizations_or?: string[];
    collections_or?: string[];
    organizers_or?: string[];
    include_mature_events?: boolean;
    include_manual_events?: boolean;
    page?: number;
    page_size?: number;
    search_id?: string;
    source?: any;
    special?: string[];
    point_radius?: any;
    client_timezone?: any;
    duration_range?: any;
    start_hour_range?: { start_hour: number; end_hour: number };
    aggs?: AggType;
    continuation?: string;
    sort?: SortType;
    exclude?: {
        ids?: string[];
    };
    image?: boolean;
    category?: string;
    bucket?: string;
    related_events?: { event_ids: string[]; exclude_event_ids: boolean };
}

export interface OrganicEventSearchApiResponse {
    articles: {};
    current_user_id: string;
    profile_search: {};
    article_search: {};
    profiles: {};
    events: {
        pagination: {
            object_count: number;
            page_count: number;
            page_number: number;
            page_size: number;
            continuation: string;
        };
        results: DestinationEvent[];
        aggs?: {
            dates?: { buckets: DateAggResponse[] };
            tags?: { buckets: TagAggApiResponse[] };
            organizertagsautocomplete_agg?: {
                buckets: OrganizerAutocompleteAggApiResponse[];
            };
        };
        promoted_results: DestinationEvent[];
        top_match: DestinationEvent[];
    };
    is_staff: boolean;
    search_id: string;
    es: [];
    event_search: OrganicEventSearchFiltersApiPayload;
}

export interface TagAggApiResponse {
    inflated?: {
        prefix: string;
        tag: string;
        display_name: string;
    };
    display_name: string;
    key: string;
    doc_count?: number;
}

export interface OrganizerAutocompleteAggApiResponse {
    inflated: string;
    display_name: string;
    key: string;
    doc_count: number;
}
